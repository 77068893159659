<template>
    <div>
        <Doughnut
            :chart-options="chartOptions"
            :chart-data="chartData"
            :chart-id="chartId"
            :dataset-id-key="datasetIdKey"
            :plugins="plugins"
            :css-classes="cssClasses"
            :styles="styles"
            :width="width"
            :height="height"
        />
    </div>
</template>

<script>
    import {Doughnut} from 'vue-chartjs/legacy'

    import {
        Chart as ChartJS,
        Title,
        Tooltip,
        Legend,
        ArcElement,
        CategoryScale
    } from 'chart.js'

    ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale)

    export default {
        name: 'DoughnutChart',
        components: {
            Doughnut
        },
        props: {
            dataList: null,
            currencyName: null,
            chartId: {
                type: String,
                default: 'doughnut-chart'
            },
            datasetIdKey: {
                type: String,
                default: 'label'
            },
            width: {
                type: Number,
                default: 400
            },
            height: {
                type: Number,
                default: 400
            },
            cssClasses: {
                default: '',
                type: String
            },
            styles: {
                type: Object,
                default: () => {
                }
            },
            plugins: {
                type: Array,
                default: () => []
            }
        },
        watch: {
            dataList: function (val) {
                this.chartData.labels = val.data;
                this.chartData.datasets[0].data = val.total;
                if(val.data)
                    val.data.forEach(()=>{
                        this.chartData.datasets[0].backgroundColor.push(this.getRandomColor());
                    });
            }
        },
        data() {
            return {
                chartData: {
                    labels: [],
                    datasets: [
                        {
                            backgroundColor: [],
                            data: []
                        }
                    ]
                },
                chartOptions: {
                    responsive: true,
                    maintainAspectRatio: false
                }
            }
        },
        methods: {
            getRandomColor() {
                let letters = '0123456789ABCDEF';
                let color = '#';
                for (let i = 0; i < 6; i++) {
                    color += letters[Math.floor(Math.random() * 16)];
                }
                return color;
            }
        },
        mounted() {
            this.chartData.labels = this.dataList.data;
            this.chartData.datasets[0].data = this.dataList.total;
            if(this.dataList.data)
                this.dataList.data.forEach(()=>{
                    this.chartData.datasets[0].backgroundColor.push(this.getRandomColor());
                });

        }
    }
</script>
  